<template>
  <div class="flex" style="height:100%">
    <EditorSider
      v-if="hasSider"
      :activeIndex.sync="currentIndex"
      :len="len"
      :modifiable="false"
      style="margin-right:1.25em;"
    />
    <component
      v-auto-height
      class="flex1 right-seg"
      :is="previewName"
      :hb="hb"
      :showImgCaption="showImgCaption"
      :audioInfo="audioInfo"
    />
  </div>
</template>

<script>
import PreviewVerse from './PreviewVerse';
import PreviewView from './PreviewView';
import PreviewIntensive from './PreviewIntensive';
import PreviewLongSentence from './PreviewLongSentence';
import PreviewGame from './PreviewGame';
import PreviewNotFound from './PreviewNotFound';
import computeLenMixin from './computeLenMixin4wrapper';
export default {
  components: {
    PreviewVerse,
    PreviewView,
    PreviewIntensive,
    PreviewNotFound,
    PreviewLongSentence,
    PreviewGame
  },
  directives: {
    autoHeight: {
      bind: (el, binding) => {
        setTimeout(() => {
          el.style.maxHeight = el.clientWidth * 2 + 'px';
          el.style.minHeight = el.clientWidth * 1.5 + 'px'
        }, 16);
      }
    }
  },
  props: {
    hb: {},
    audioInfo: {},
    hasSider: {
      default: true
    },
    showImgCaption: {
      default: false
    }
  },
  mixins: [computeLenMixin],
  computed: {
    previewName() {
      if (/^(en|cn)Verse$/.test(this.hb.contentType)) {
        return 'PreviewVerse';
      }
      if (/^(en|cn)Intensive$/.test(this.hb.contentType)) {
        return 'PreviewIntensive';
      }
      // enIenglish 网络文章详情 已被后台转化成view模型返回，所以采用view预览
      if('enIenglish'===this.hb.contentType){
        return 'PreviewView'
      }
      if (/^(en|cn)(View|Words|Sentence|Idiom|Teaching)$/.test(this.hb.contentType)) {
        return 'PreviewView';
      }
      if (/^(en|cn)LongSentence$/.test(this.hb.contentType)) {
        return 'PreviewLongSentence';
      }
      if(['cnIdiomComb','cnFlying'].includes(this.hb.contentType)){
        return 'PreviewGame'
      }
      
      return 'PreviewNotFound';
    }
  },
  mounted() {
    this.currentIndex = 0;
  }
};
</script>

<style>
/* 这个必须是 公共样式 才能被动态组件使用： */
.right-seg {
  height: 100%;
  outline: 1px solid #ddd;
  overflow-y: auto;
}
</style>