<template>
<div>
  <div class="root" ref="nbprint" id="nbprint" name="view" @click="$emit('click',$event)">
    <div v-html="html"></div>

  </div>
    <!-- div class="topleft">
    <Button @click = "onPrint">打印</Button>
    </div-->
    <div class="topleft">
    <Button v-print="printObj">打印</Button>
    </div >
</div>
</template>

<script>
import PreviewService from "./PreviewService";
import html2canvas from 'html2canvas';
import printJS from 'print-js';
export default {
  props: {
    hb: {},    
    audioInfo: {},
    showImgCaption: {
      default: false
    }
  },
  name: "nb-print",
  data() {
    return {
        html: "",
        printAre: {
          id: "nbprint",
          popTitle: "文章打印",
          extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
        },
        printObj:{
           id:"#viewPrint",
           popTitle:"&nbsp;",
           extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>,<style>  #viewPrint { max-width:100%; font-size:68px; word-wrap:break-word } <style>'
        },
        printOption: {
			id: 'nbprint', // 打印元素的id 不需要携带#号
			preview: true, // 开启打印预览
			previewTitle: '打印预览', // 打印预览标题
			popTitle: '文章打印', // 页眉标题 默认浏览器标题 空字符串时显示undefined 使用html语言
            
            // 新的 CSS 样式表， 并使用,（仅打印本地范围）分隔多个节点
			extraCss: '<meta http-equiv="Content-Language"content="zh-cn"/>',
			previewBeforeOpenCallback: () => {
				console.log("触发打印预览打开前回调");
			},
			previewOpenCallback: () => {
				console.log("触发打开打印预览回调");
			},
			beforeOpenCallback: () => {
				console.log("触发打印工具打开前回调");
			},
			openCallback: () => {
				console.log("触发打开打印工具回调");
			},
			closeCallback: () => {
				console.log("触发关闭打印工具回调");
			},
			clickMounted: () => {
				console.log("触发点击打印回调");
			}
		}

    };
  },
  methods: {
    initPageHtml() {
      this.html = PreviewService.getHtml(
        this.hb,
        this.currentIndex,
        this.showImgCaption
      );
      this.$forceUpdate();
    },

    handlePrint() {
     printJS({
       printable: nbprint, // 'printFrom', // 标签元素id
       type: 'html',
       targetStyles: ['*'],
     })
   },
    // printJs转图片打印
    onPrint() {
        const printContent = this.$refs.nbprint;
        // 获取dom 宽度 高度
        const width = printContent.clientWidth;
        const height = printContent.clientHeight;

        console.log(height);
        // 创建一个canvas节点
        const canvas = document.createElement('canvas');
    
        const scale = 1; // 定义任意放大倍数，支持小数；越大，图片清晰度越高，生成图片越慢。
        canvas.width = width * scale; // 定义canvas 宽度 * 缩放
        canvas.height = height * scale; // 定义canvas高度 *缩放
        canvas.style.width = width * scale + 'px';
        canvas.style.height = height * scale + 'px';
        canvas.getContext('2d').scale(scale, scale); // 获取context,设置scale
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; // 获取滚动轴滚动的长度
        const scrollLeft = document.documentElement.scrollLeft || document.body.scrollLeft; // 获取水平滚动轴的长度
    
        html2canvas(printContent, {
            canvas,
            backgroundColor: null,
            letterRendering: true,
            useCORS: true,
            windowHeight: document.body.scrollHeight,
            scrollX: -scrollLeft, // 解决水平偏移问题，防止打印的内容不全
            scrollY: -scrollTop
        }).then((canvas) => {
                const url = canvas.toDataURL('image/png')
                printJS({
                printable: url,
                type: 'image',
                documentTitle: '', // 标题
                style: '@page{size:auto; text-align: left; letter-spacing: 6px; margin: 0cm 1cm 0cm 1cm;}' // 去除页眉页脚
                })
            }).catch(err=>{
                console.error(err);
        })
    }
  },
  mounted(){
    this.$eventBus.$on('hbChange',()=>{
      console.log('view preview')
      this.initPageHtml()
    })
  },
  watch: {
    currentIndex: {
      handler() {
        this.initPageHtml();
      },
      immediate: true
    },
    hb() {
      this.initPageHtml();
    },
    audioInfo() {
      if (!this.audioInfo) {
        return;
      }
      PreviewService.markHtml(
        this.$el,
        this.audioInfo.text,
        this.audioInfo.paragraphKey,
        this.currentIndex
      );
    }
  }
};

</script>

<style scoped lang="scss">
::v-deep {
  .image {
    width: max-content;
    max-width: 100%;
    margin: 0 auto;
  }
  .preview-view-img {
    max-width: 100%;
    display: block;
    margin: 0 auto;
  }
  .preview-view-p {
    padding: 0 0.5em;
    font-size:24px;
  }

  .preview-audio-active {
    color: red;
  }
  
  .root{
    text-align: left;
    letter-spacing: 2px;
    line-height:50px;
    word-wrap:break-word;
  }

  .topleft {
  position: absolute;
  top:0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  }

  .topright {
  position: absolute;
  top:0px;
  left: 100px;
  align-items: center;
  justify-content: center;
  }

}
</style>

<!-- style>

@media print {
        @page {
          size:  auto;
        }
        body, html {
          height: auto !important;
        }
      }

</style -->