<template>
  <Tabs type="card" v-model="hb.showType" @on-click="selType" style="padding:0 .5em">
    <TabPane label="精讲" name="jj">
      <div v-for="(jj,i) in hb.preciseExplainList" :key="'jj'+i" v-show="i===currentIndex">
        <Item4LongSentence v-for="(jjItem,j) in jj.divList" :item="jjItem" :key="j" />
      </div>
    </TabPane>
    <TabPane label="练习" name="lx" @click="hb.showLX=true;currentIndex=0">
      <div v-for="(lx,i) in hb.exercisesList" :key="'lx'+i" v-show="i===currentIndex">
        <Item4LongSentence v-for="(lxItem,j) in lx.divList" :item="lxItem" :key="j" />
      </div>
    </TabPane>
  </Tabs>
</template>

<script>
import Item4LongSentence from './Item4LongSentence';
export default {
  components: { Item4LongSentence },
  props: {
    hb: {}
  },
  data() {
    return {};
  },
  methods: {
    selType(type) {
      this.currentIndex = 0;
    }
  },
  mounted() {
    this.$set(this.hb, 'showType', 'jj');
  }
};
</script>

<style scoped>
::v-deep .ivu-tabs-tab,
::v-deep .ivu-tabs-nav-container,
::v-deep .ivu-table {
  font-size: unset;
}
::v-deep .ivu-tabs-bar .ivu-tabs-nav-container {
  height: unset !important;
}
</style>