<template>
  <div name="verse" v-fontSize v-html="html"></div>
</template>

<script>
import PreviewService from "./PreviewService";
function setFontSize(el){
el.style.fontSize = Math.floor(el.clientWidth * 0.06/4)*4 + "px";
}
export default {
  directives: {
    fontSize: {
      bind: function(el) {
        setTimeout(() => {
          setFontSize(el)
        }, 16);
      },
      update: function(el) {
        setFontSize(el)
      }
    }
  },
  props: {
    hb: {},
    audioInfo: {}
  },
  data() {
    return {
      html: ""
    };
  },
  methods: {
    initPageHtml() {
      this.html = PreviewService.getHtml(this.hb, this.currentIndex);
      this.$forceUpdate();     
    }
  },
  mounted() {
    this.$eventBus.$on("hbChange", () => {
      console.log('verse preview')
      this.initPageHtml();
    });
  },
  watch: {
    currentIndex: {
      handler() {
        this.initPageHtml();
      },
      immediate: true
    },
    hb() {
      this.initPageHtml();
    },
    audioInfo() {
      if (!this.audioInfo) {
        return;
      }
      PreviewService.markHtml(
        this.$el,
        this.audioInfo.text,
        this.audioInfo.paragraphKey,
        this.currentIndex
      );
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .preview-verse-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .preview-verse-txtblock {
    position: absolute;
    border: none;
    background: transparent;
    overflow-y: hidden;
    resize: none;
  }
  .preview-audio-active {
    color: red;
  }
}
</style>