<template>
  <div class="m-t-xs">
    <h3>{{title}}</h3>
    <div v-if="divStyle==='list'">
      <Table :columns="columns" :data="item.componentList"></Table>    
    </div>
    <div v-else-if="divStyle==='text'">{{item.content}}</div>
    <div v-else-if="divStyle==='html'" v-html="item.content"></div>
    <div v-else>{{divStyle}}</div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          title: '结构名称',
          key: 'name',
          width: 160
        },
        {
          title: '成分',
          key: 'wordTxt'
        }
      ]
    };
  },
  computed: {
    title() {
      switch (this.item.chapterCode) {
        case 'source':
          return '来源';
        case 'analysis':
          return '分析';
        case 'wordAnalysis':
          return '单词';
        case 'difficulty':
          return '难点';
        case 'translation':
          return '翻译';
        case 'origin':
          return '原文';
        case 'formatOrigin':
          return '带角标原文';
        case 'trunk':
          return '主干';
        case 'component':
          return '成分';
      }
      return this.item.chapterCode;
    },
    divStyle() {
      switch (this.item.chapterCode) {
        case 'component':
          return 'list';

        case 'trunk':
        case 'formatOrigin':
        case 'translation':
          return 'html';

        case 'origin':
        case 'source':
        case 'analysis':
        case 'wordAnalysis':
        case 'difficulty':
          return 'text';
      }
      return this.item.chapterCode;
    }
  }
};
</script>

<style>
</style>