<template>
  <PreviewIntensivePoetry :hb="hb" :audioInfo="audioInfo" v-if="'cnIntensivePoetry'===hb.category" />
  <PreviewIntensiveNormal :hb="hb" :audioInfo="audioInfo" :showSubTitle="'cnIntensiveIdiom'===hb.category" v-else />
 
</template>
<script>
import PreviewIntensivePoetry from "./PreviewIntensivePoetry";
import PreviewIntensiveNormal from './PreviewIntensiveNormal'
export default {
  components: { PreviewIntensivePoetry,PreviewIntensiveNormal },
  props: {
    hb: {},
    audioInfo: {}
  }
};
</script>

<style scoped>
</style>