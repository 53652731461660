<template>
  <div :style="{backgroundImage:'url('+info.backgroudUrl+')'}" class="poetry-wrapper">
    <div class="poetry-card">
      <div>{{articleTitle}}</div>
      <div style="margin-bottom:.5em">{{info.dynasty}} ：{{info.author}}</div>
      <pre>{{info.contentDivList|showItem('content')}}</pre>      
    </div>

    <div class="poetry-footer">
      <div class="txt-wrp">
        <span :class="{active:footerContent==='appreciate'}" @click="footerContent='appreciate'">
          <i>■</i> 赏析
        </span>
        <span :class="{active:footerContent==='example'}" @click="footerContent='example'">
          <i>■</i> 解析
        </span>
      </div>
      
      <pre v-if="footerContent==='example'">{{info.contentDivList|showItem('example')}}</pre>
      <pre v-if="footerContent==='appreciate'">{{info.contentDivList|showItem('appreciate')}}</pre>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hb: {},
    audioInfo: {}
  },
  filters: {
    showItem(list, chapterCode) {
      let item = list.find(ele => ele.chapterCode === chapterCode);
      return item ? item.content : "";
    }
  },
  computed: {
    info() {
      return this.hb.attributeList[this.currentIndex];
    },
    articleTitle() {
      return this.hb.contentTitle[this.currentIndex].content;
    }
  },
  data() {
    return {
      footerContent: "example" // 'example' or 'appreciate'
    };
  }
};
</script>

<style scoped>
pre{
  margin:.5em 0;
  white-space: pre-wrap;
}
.poetry-wrapper {
  background-size: 100% 100%;
  position: relative;
  font-size: 1.75em;
}
.poetry-card {
  background: rgba(255, 255, 255, 1);
  text-align: center;
  border-radius: 1em;
  padding: 1em;
  width: 63%;
  height: 54%;
  margin: 25% auto;
  box-shadow: 0 0 5px -2px black;
}
.poetry-card>pre{
  max-height: calc(100% - 3.5em);
  overflow: auto;
}
.poetry-footer {
  position: absolute;
  bottom: 0;
  height: 25%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
}
.poetry-footer>pre{
  max-height: calc(100% - 2.875em);
  overflow: auto;
}
/* -------------------- */
.txt-wrp {
  text-align: center;
  padding: 0.25em 0 0;
}
.txt-wrp > span {
  cursor: pointer;
  margin: 0 0.25em;
  padding: 0 0.25em;
}
.txt-wrp > span.active {
  color: rgb(59, 206, 211);
}
</style>