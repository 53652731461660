<template>
  <div>
      <h2>{{hb.title}}</h2>
      <div v-for="(item,i) in list" :key="i">{{item.content}}</div>
  </div>
</template>
<script>
import PreviewIntensivePoetry from "./PreviewIntensivePoetry";
import PreviewIntensiveNormal from './PreviewIntensiveNormal'
export default {
  components: { PreviewIntensivePoetry,PreviewIntensiveNormal },
  props: {
    hb: {},
    audioInfo: {}
  },
  computed:{
      list(){
          return this.hb?.contentMaterialList?.[0]?.materialList??[]
      }
  }
};
</script>

<style scoped>
</style>