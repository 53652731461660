<template>
  <div style="font-size:1.5em">
    <div class="idiom-title">
      <span class="title-ch" v-for="(ch,i) in articleTitleChars" :key="i">{{ch}}</span>
    </div>

    <div v-for="(item,i) in infos" :key="i" style="padding:.5em 2em;">
      <div class="subtitle" v-if="showSubTitle">{{item.titleName||item.chapter}}</div>
      <pre v-if="item.divStyle==='txt'">{{item.content}}</pre>
      <template v-else-if="item.divStyle==='video'">
        <div v-if="item.url">
          <video :src="item.url" controls style="max-width:100%;max-height:100%" />
        </div>
        <div v-else style="text-align:center;">
          <img src="/images/video-ico.svg" />
          <br />视频标记
        </div>
      </template>
      <div v-else-if="item.divStyle==='image'" style="text-align:center">
        <img :src="item.url||'/images/img-mark.jpg'" style="max-width:100%;max-height:215px" />
      </div>
      <div v-else>未知类型：{{item.divStyle}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hb: {},
    audioInfo: {},
    showSubTitle:{
      default:true
    }
  },
  computed: {
    infos() {
      return this.hb.attributeList[this.currentIndex].contentDivList;
    },
    articleTitleChars() {
      return this.hb.contentTitle[this.currentIndex].content.split("");
    }
  }
};
</script>

<style scoped>
pre {
  margin: 0.5em 0;
  white-space: pre-wrap;
  font-family: inherit;
}
.idiom-title {
  font-size: 1.875em;
  padding: 0.5em 0;
  display: flex;
  justify-content: center;
}
.title-ch {
  width: 1.5em;
  text-align: center;
}
.subtitle::before {
  content: "■";
  display: inline-block;
  vertical-align: middle;
  margin: -0.5em 0.4em auto -1em;
  color: rgb(59, 206, 211);
}
</style>