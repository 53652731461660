export default {
    computed: {
        len() {
            if (/^(en|cn)Verse$/.test(this.hb.contentType)) {
                return this.hb.backGroundList.length;
            }
            if (/^(en|cn)(View|Words|Sentence|Idiom)$/.test(this.hb.contentType)) {
                return this.hb.mdContentList.length;
            }
            if (/^(en|cn)LongSentence$/.test(this.hb.contentType)) {
                if (this.hb.showType === 'jj') {
                    return this.hb.preciseExplainList.length;
                } else {
                    return this.hb.exercisesList.length;

                }
            }
            // 精读 和 教材只有1篇：
            return 1;
        }
    }
}