class HbPreviewService { }
let getHtmlByVerse = (hb, ind) => {
  let info = hb.backGroundList[ind];
  if (!info) {
    return;
  }
  let str = `<div class="preview-verse-wrapper" style="color:${info.fontColor};background-image:url(${info.url})">`;

  for (let txt of info.divElementList) {
    let h = txt.divContent
    // .replace(new RegExp(" ", "g"), "&nbsp;")
    // .replace(new RegExp("\n", "g"), "<br>");
    str += `<textarea disabled class="preview-verse-txtblock verse-text" style="top:${txt.divY *
      100}%;left:${txt.divX * 100}%;width:${txt.divWidth *
      100}%;height:${100 - txt.divY *100}%">${h}</textarea>`;
  }
  str += `</div>`;
  return str
}

let getHtmlByView = (hb, ind, showImgCaption) => {
  let htmlPrinter = `<div id="viewPrint">`;
  let html = hb.mdContentList[ind].replace(/<img/g, '<div style="page-break-after:always"></div><img class="preview-view-img"').replace(/<p/g, '<p class="preview-view-p"')
  if (!showImgCaption) {
    html = html.replace(/<figcaption/g, '<figcaption class="hidden"')
  } else {
    html = html.replace(/<figcaption/g, '<figcaption style="text-align: center;background: #eee;"')
  }
  if (hb.contentTitle[ind]) {
    html = `<h3 style="font-size: 1.75em;padding: 0.25em 0;">${hb.contentTitle[ind].content}</h3>` + html
    if (hb.contentTitle[ind].url) {
      html = `<img class="preview-view-img" src="${hb.contentTitle[ind].url}" />` + html
    }

  }
  html = htmlPrinter + html + `</div>`;
  return html
}


HbPreviewService.prototype.getHtml = (hb, ind, showImgCaption) => {

  if (/Verse/.test(hb.contentType)) {
    return getHtmlByVerse(hb, ind)
  } else {
    return getHtmlByView(hb, ind, showImgCaption)
  }

}

/**
 * @param {HTMLDivElement} div
 * @param {String} indKey
 */
let markHtmlOfView = (div, indKey) => {
  let item, txtNode
  if ('t' === indKey) {
    item = div.getElementsByTagName('h3').item(0)
    txtNode = item.childNodes.item(0)
  } else {
    item = div.getElementsByTagName('p').item(+(indKey.split('-')[0].replace('p', '')))
    txtNode = Array.from(item.childNodes).filter(ele => ele.nodeType === ele.TEXT_NODE && !!ele.textContent.trim())[+indKey.split('-')[1]]
  }
  return { item, txtNode }
}

/**
 * @param {HTMLDivElement} div
 * @param {String} indKey
 */
let markHtmlOfVerse = (div, indKey) => {
  let item, txtNode
  if ('t' === indKey) {
    return;
  } else {
    let i = +indKey.split('-')[1]
    item = div.getElementsByClassName('preview-verse-txtblock').item(i)
    if (!item) {
      return;
    }
    txtNode = Array.from(item.childNodes).filter(ele => ele.nodeType === ele.TEXT_NODE && !!ele.textContent.trim())[+indKey.split('-')[2]]
  }
  return { item, txtNode }
}


/**
 * @param {HTMLDivElement} div
 * @param {String} txt 要标红的文字
 * @param {String} indKey 配音的paragraphKey值 
 * @param {number} ind 标签页标
 */
HbPreviewService.prototype.markHtml = (div, txt, indKey, ind) => {

  // 先清除掉已有样式：
  let act = div.getElementsByClassName('preview-audio-active').item(0)
  if (act) {
    act = act.parentElement
    act.parentElement.replaceChild(document.createTextNode(act.textContent), act)
  }
  if (!txt || !txt.trim()) {
    return;
  }

  // 找到indKey指定的节点：
  let type = div.getAttribute('name')
  let nodeInfo
  if ('view' === type) {
    nodeInfo = markHtmlOfView(div, indKey)
  } else {
    if (indKey.indexOf('p' + ind) !== 0) {
      console.log('播放的录音不在预览的当前页,或者这是老数据')
      return;
    }
    nodeInfo = markHtmlOfVerse(div, indKey)
  }
  if (!nodeInfo || !nodeInfo.txtNode || nodeInfo.txtNode.textContent.indexOf(txt) < 0) {
    return;
  }
  // 添加样式
  // TODO 同一行，如果有相同的内容，刚好相同的内容都独立配音了，这里还有问题。
  let { item, txtNode } = nodeInfo
  let span = document.createElement('span')
  span.innerHTML = txtNode.textContent.replace(txt, `<span class="preview-audio-active">${txt}</span>`)
  item.replaceChild(span, txtNode)
}

export default new HbPreviewService()